@import "../../scss/variables/index";

.popup {
  position: absolute;
  width: 40em;
  margin: 0 auto;
  padding-top: 2em;
  border-radius: 0.4em;
  background-color: #fff;
  border: 0.1em solid #e1e7eb;
  box-shadow: 0 0.2em 0.3em 0 rgba(0, 0, 0, 0.12);
}

.popup > i.fa {
  font-size: 5.2em;
  line-height: 1em;
  color: #5d6e7f;
  padding: 0 0.3em;
  float: left;
}

.popup > h1 {
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  font-weight: normal;
  font-size: 2em;
  line-height: 1.5em;
  padding-left: 4em;
  color: #414141;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.popup > p {
  font-family: "Calibri Regular", "Calibri", sans-serif;
  font-size: 1.6em;
  line-height: 1.5em;
  padding-left: 5em;
  color: #5d6e7f;
}

.popup > .buttons {
  padding: 1em;
  margin-top: 2.5em;
  background-color: #f6f6f6;
  border-top: 0.1em solid #e1e7eb;
  text-align: right;
}

.popup > .buttons > .button {
  margin-left: 1em;
}

.popup.danger .buttons > .button.primary {
  background-color: #eb2c51;
}

.popup.danger .buttons > .button.primary:hover {
  background-color: #d32748;
}
