@font-face {
  font-family: "Sommet Rounded Regular";
  src: url("SommetRoundedRegular.eot");
  src: local("O"),
        url("SommetRoundedRegular.woff") format("woff"),
        url("SommetRoundedRegular.ttf") format("truetype"),
        url("SommetRoundedRegular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
