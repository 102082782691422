@import "../../scss/variables/index";

.grid {
  display: flex;
  flex-flow: column nowrap;
}

.grid.loading > .activity-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grid.loading .grid-row {
  opacity: 0.2;
}

.grid .grid-row {
  width: 100%;
  height: 5.2em;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 0.1em solid #e1e7eb;
  background-color: $color-white;

  &--warning {
    background-color: $color-error-transparent;
  }
}

.grid .grid-header.grid-row {
  background-color: #f6f6f6;
}

.grid .grid-row .grid-cell {
  color: #5d6e7f;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 0;
  word-break: break-word;
  margin-right: 5px;
  margin-left: 5px;
  overflow: hidden;
}

.grid .grid-row .grid-cell.grow0 {
  flex-grow: 0;
  min-width: 1.6em;
  padding: 1.7em 2.2em 1.8em 0.5em;
}

.grid .grid-row .grid-cell.grow0 > .grid-label {
  line-height: 0;
}

.grid .grid-row .grid-cell.grow2 {
  flex-grow: 2;
}

.grid .grid-row .grid-cell.grow3 {
  flex-grow: 3;
}

.grid .grid-row .grid-cell > .grid-label {
  font-family: "Calibri Regular", "Calibri", sans-serif;
  font-size: 1.4em;
  line-height: 3.7em;
  overflow: hidden;
  min-width: 16px;
}

.grid .grid-header.grid-row .grid-cell {
  text-transform: uppercase;
  color: #5d6e7f;
}

.grid .grid-cell-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
}

.grid .grid-cell-truncate-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
}
