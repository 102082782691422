@font-face {
  font-family: "Calibri Regular";
  src: url("CalibriRegular.eot");
  src: local("O"),
        url("CalibriRegular.woff") format("woff"),
        url("CalibriRegular.ttf") format("truetype"),
        url("CalibriRegular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
