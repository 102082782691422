
@import "../../scss/variables/index";

.header {
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  text-align: center;
  color: $color-white;
  min-height: 10em;
  padding: 5em 0;
  flex-shrink: 0;

    &__title {
      font-size: 4em;
      line-height: 1.9em;
      color: $color-white;
    }

    &__subtitle {
      font-size: 2em;
      line-height: 1em;
    }

}
