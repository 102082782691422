
.notification-manager > .alert {
  position: relative;
  margin: 1em 1em 0 0;
}

.notification-manager > .alert.animated-notification-enter {
  top: 4em;
  opacity: 0;
}

.notification-manager > .alert.animated-notification-enter.animated-notification-enter-active {
  top: 0;
  opacity: 1;
  transition: 200ms ease-out;
}

.notification-manager > .alert.animated-notification-exit {
  left: 0;
  opacity: 1;
}

.notification-manager > .alert.animated-notification-exit.animated-notification-exit-active {
  left: 40em;
  height: 0;
  margin: 0;
  padding: 0 1.5em;
  border: 0;
  opacity: 0;
  transition: 200ms ease-in;
}
