@import "../../scss/variables/index";

.badge {
  position: relative;
  display: inline-block;
  font-family: "Calibri Regular", "Calibri", sans-serif;
  text-align: center;
  color: #fff;
  font-size: .7em;
  line-height: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  min-width: 2em;
  padding: 0 0.125em;
  background-color: #5d6e7f;
  margin: 0 0.625em;
}

.badge.big {
  line-height: 1.5625em;
  height: 1.5625em;
  border-radius: 0.785em;
  min-width: 2em;
  padding: 0 0.25em;
}
