.transition {

  &-item {
    animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    left: 0;
    width: 100%;

    &-enter {
      position: absolute;
      z-index: 2;
    }

    &-exit {
      position: absolute;
      z-index: 1;

      &-done {
        opacity: 0;
      }
    }
  }
}

.manage-view {
  &.transition-item {
    &-enter {
      left: -100vw;

      &-active {
        animation-name: slideFromLeft;
        animation-duration: 400ms;
      }
    }

    &-exit {
      left: 0;

      &-active {
        animation-name: slideToLeft;
        animation-duration: 400ms;
      }
    }
  }
}

.addon-view {
  &.transition-item {
    &-enter {
      left: 100vw;

      &-active {
        animation-name: slideFromRight;
        animation-duration: 400ms;
      }
    }

    &-exit {
      left: 0;

      &-active {
        animation-name: slideToRight;
        animation-duration: 400ms;
      }
    }
  }
}

@keyframes slideFromRight {
  from {
    left: 100vw;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  from {
    left: -100vw;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes slideToRight {
  from {
    left: 0;
    opacity: 1;
  }

  to {
    left: 100vw;
    opacity: 0;
  }
}

@keyframes slideToLeft {
  from {
    left: 0;
    opacity: 1;
  }

  to {
    left: -100vw;
    opacity: 0;
  }
}
