.activity-indicator {
  display: inline-block;
  border-radius: 0.4em;
  padding: 2em;
  background-color: transparent;
  text-align: center;
}

.activity-indicator > div {
  padding-top: 1em;
}

.activity-indicator > div > p {
  color: #5d6e7f;
	font-family: "Calibri Regular", "Calibri", sans-serif;
	font-size: 1.4em;
  line-height: 1em;
}

.activity-indicator > .animated {
  animation-duration: 1500ms;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  animation-iteration-count: infinite;
}

@keyframes spin-left {
  from { transform:rotate(0deg); }
  to { transform:rotate(-359deg); }
}

@keyframes spin-right {
  from { transform:rotate(0deg); }
  to { transform:rotate(359deg); }
}
