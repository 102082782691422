.grid.navigatable > .grid-row:hover {
  background-color: #e9f2ec;
  cursor: pointer;
}

.grid .grid-row .grid-cell.grid-navigation {
  flex-grow: 0;
  min-width: 1.6em;
}

.grid .grid-row .grid-cell.grid-navigation .grid-label {
  width: 100%;
  text-align: center;
}
