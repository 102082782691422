@import "../../../../scss/variables/index";
@import "../../../../scss/mixins/index";

.addon-view {
  height: 100%;
}

.content-provider {
  display: flex;
  flex-flow: row wrap;
  height: 100%;

  &__content {
    flex: 1 1 500px;
  }

  &__sidebar {
    width: 370px;
    flex: 0 0 auto;
    background-color: $color-white;
    margin-left: $space-medium;
    display: flex;
    flex-flow: column nowrap;
    padding: $space-small 0;
    margin-right: -$space-large;

    @include media-till-tablet-portrait {
      margin: 0;
      width: 100%;
    }

    &-section {
      display: flex;
      flex-flow: column nowrap;
      margin: $space-medium $space-medium 0;

      & + & {
        border-top: 1px solid $color-grey;
        padding-top: $space-medium;
      }

      &-title {
        @include text-h3;

        .ask {
          color: $color-error;
        }

        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      &-message {
        @include text-p;
        margin-top: $space-small;

        &--error {
          color: $color-error;
        }

        &--success {
          color: $color-green;
        }
      }

      &-button {
        margin: $space-tiny-half;
        display: inline-block;

        &s {
          margin: -$space-tiny-half;
          margin-top: $space-small;
        }
      }

      &-status {
        @include text-p;

        &--error {
          color: $color-error;
        }

        &--success {
          color: $color-green;
        }
      }
    }
  }

  &__info {
    margin-top: $space-large;
  }

  &__addon {
    &-name {
      @include header-h3;
    }

    &-author {
      @include text-h4;

      &-text {
        color: $color-text;
      }
    }

    &-image {
      width: 100%;

      &-wrap {
        display: flex;
        width: 128px;
        max-height: 128px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        margin-right: $space-small;
        flex-shrink: 0;
      }
    }

    &-description {
      @include text-p;
      min-height: 64px;
      margin-top: $space-medium;
      display: flex;
    }
  }

  &__actions {
    display: flex;
    flex-flow: row wrap;
    padding: 0 $space-small $space-small;
    border-bottom: 1px solid $color-grey;

    & > * {
      margin: $space-tiny-half;
    }
  }

  &__extensions {
    margin-top: $space-large;
    margin-bottom: $space-large;

    &-title {
      @include header-h4;
    }

    .button {
      margin-top: $space-small;
    }

  }

  &__config {
    margin-top: $space-small;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    &-link {
      @include text-p;
      color: $color-secondary;
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: 0;
    }

    &-age {
      @include text-p;
      color: $color-text-dimmed;
      margin-top: $space-tiny;
    }
  }

  &__table {
    margin-top: $space-medium;
  }

  &__btn-refresh {
    margin-top: $space-small;
  }
}

.table {
  &__expand-row-icon {
    color: $color-grey-dark;
    background-color: transparent;
    border: none;
    outline: 0;
  }

  .ant-table {
    color: $color-grey-dark;
    border: none;
    font-family: $font-secondary;

    table {
      border-collapse: collapse;
    }

    &-thead {
      text-transform: uppercase;
      
      tr {
        th {
          color: $color-grey-dark;
        }
      }
    }

    &-tbody {
      background-color: $color-white;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      overflow: hidden;
    }

    &-body {
      margin: 0!important;
    }

    &-content {
      background-color: transparent;
    }
  }
}

.status-tag {
  &__icon {
    margin-right: 5px;
  }

  &--error {
    color: $color-error;
  }

  &--deploy {
    color: $color-grey-dark;
  }

  &--success {
    color: $color-primary;
  }
}

.status-description {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 5px 10px;

  &--error {
    background-color: $color-error-transparent;
  }

  &__text {
    display: inline-block;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    flex-grow: 1;
    width: 100%;
    flex-basis: 100%;
  }

  &__icon {
    margin-top: 2px;
    margin-right: 7px;

    &--error {
      color: $color-error;
    }

    &--success {
      color: $color-green;
    }

    &--pending {
        color: $color-grey-dark;
    }

    &--disabled {
        color: $color-grey-dark;
    }

    &--waiting {
        color: $color-lemon-yellow;
    }
  }
}

