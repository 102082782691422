@import "../../scss/variables/index";

.button {
  font-size: 1em;
  height: 3.6em;
  padding: 0 1.5em;
  border: 0;
  border-radius: 0.4em;
  background-color: #e1e7eb;
  box-shadow: inset 0 -0.2em 0 0 #0000001a;
  color: #2a3b4c;
  outline: none;
}

.button:hover {
  background-color: #cfd4d7;
  box-shadow: none;
}

.button:active {
  background-color: #e1e7eb;
}

.button:disabled {
  background-color: #e1e7eb;
  opacity: 0.5;
}

.button:disabled:hover {
  box-shadow: inset 0 -0.2em 0 0 #0000001a;
}

.button > .icon,
.button > .label {
  font-size: 1.6em;
  line-height: 1em;
}

.button > .icon {
  min-width: 0.625em;
}

.button > .label {
  font-family: "Calibri Regular", "Calibri", sans-serif;
  min-width: 2.5em;
}

.button > .icon + .label,
.button > .label + .icon {
  padding-left: 0.625em;
}

.button.big {
  height: 5em;
}

.button.big > .icon,
.button.big > .label {
  font-size: 2em;
  line-height: 1em;
}

.button.big > .icon {
  min-width: 1.5em;
}

.button.big > .label {
  min-width: 3.5em;
}

.button.small {
  height: 3em;
}

.button.small > .icon,
.button.small > .label {
  font-size: 1.4em;
  line-height: 1em;
}

.button.primary {
  background-color: #25bd59;
  color: #fff;
}

.button.primary:hover {
  background-color: #21a94f;
}

.button.primary:active {
  background-color: #25bd59;
}

.button.primary:disabled {
  background-color: #25bd59;
}

.button.cta {
  background-color: #f58025;
  color: #fff;
}

.button.cta:hover {
  background-color: #dc7221;
}

.button.cta:active {
  background-color: #f58025;
}

.button.cta:disabled {
  background-color: #f58025;
}

.button.ghost,
.button.ghost-inverted {
  background: transparent;
  box-shadow: none;
  border-width: 0.1em;
  border-style: solid;
}

.button.ghost:disabled:hover,
.button.ghost-inverted:disabled:hover {
  box-shadow: none;
}

.button.ghost {
  border-color: #25bd59;
  color: #25bd59;
}

.button.ghost:hover {
  border-color: #21a94f;
  color: #21a94f;
}

.button.ghost:active {
  border-color: #25bd59;
  color: #25bd59;
}

.button.ghost:disabled {
  border-color: #25bd59;
  color: #25bd59;
}

.button.ghost-inverted {
  border-color: #fff;
  color: #fff;
}

.button.ghost-inverted:hover {
  border-color: #dfe6e9;
  color: #dfe6e9;
}

.button.ghost-inverted:active {
  border-color: #fff;
  color: #fff;
}

.button.ghost-inverted:disabled {
  border-color: #dfe6e9;
  color: #dfe6e9;
}

.button.ghost-inverted-fill {
  background-color: #fff;
  color: #25bd59;
}

.button.ghost-inverted-fill:hover {
  background-color: #dfe6e9;
  color: #21a94f;
}

.button.ghost-inverted-fill:active {
  background-color: #fff;
}

.button.ghost-inverted-fill:disabled {
  background-color: #fff;
}

.button.ghost-inverted-fill:disabled:hover {
  color: #25bd59;
}
