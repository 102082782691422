@import '~antd/dist/antd.css';
@import "../../scss/variables/index";
@import "../../scss/mixins/index";

html, body {
  font-family: $font-secondary;
  font-size: 10px;
  line-height: 1em;
  background-color: #f6f6f6;
  color: #5d6e7f;
  height: 100%;
}

body,
body * {
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__wrapper {
    height: 100%;
  }

  &__alert {
    color: #cc9803;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #cc9803;
    font-weight: bold;

    .ant-alert-message {
      color: #cc9803;
    }

    .ant-alert-icon {
      color: #cc9803;
    }
  }
}


a {
  outline: 0;
}

.content {
  @include content;
  flex-grow: 1;

  &__container {
    height: 100%;
    position: relative;
  }
}

.btn {
  @include button-primary;

  &--upload {
    @include button-primary($color-white, $color-primary);
  }

  &--back {
    margin-top: $space-large;
    @include button-primary;
  }

  &--add {
    @include button-primary($color-white, $color-primary);
  }
}
