/* Primary colors */
/* Secondary colors */
.action-bar > * + * {
  margin-left: 1em; }

.activity-indicator {
  display: inline-block;
  border-radius: 0.4em;
  padding: 2em;
  background-color: transparent;
  text-align: center;
}

.activity-indicator > div {
  padding-top: 1em;
}

.activity-indicator > div > p {
  color: #5d6e7f;
	font-family: "Calibri Regular", "Calibri", sans-serif;
	font-size: 1.4em;
  line-height: 1em;
}

.activity-indicator > .animated {
  -webkit-animation-duration: 1500ms;
          animation-duration: 1500ms;
  -webkit-animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
          animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes spin-left {
  from { transform:rotate(0deg); }
  to { transform:rotate(-359deg); }
}

@keyframes spin-left {
  from { transform:rotate(0deg); }
  to { transform:rotate(-359deg); }
}

@-webkit-keyframes spin-right {
  from { transform:rotate(0deg); }
  to { transform:rotate(359deg); }
}

@keyframes spin-right {
  from { transform:rotate(0deg); }
  to { transform:rotate(359deg); }
}

/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
.activity-overlay {
  display: flex;
  padding: 3.5em;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .activity-overlay__content {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 16px; }

/* Primary colors */
/* Secondary colors */
.alert {
  overflow: hidden;
  padding: 2.2em 1.5em;
  border-width: 0.1em;
  border-style: solid;
  border-radius: 0.4em;
  width: 36em; }

.alert.success {
  background-color: #e7f9f1;
  border-color: #0f955e;
  color: #0f955e; }

.alert.error {
  background-color: #faeded;
  border-color: #d04d52;
  color: #d04d52; }

.alert.warning {
  background-color: #fff8e3;
  border-color: #cb9700;
  color: #cb9700; }

.alert.info {
  background-color: #ebf1f8;
  border-color: #457cbf;
  color: #457cbf; }

.alert > .fa {
  float: left;
  font-size: 1.4em;
  line-height: 1em; }

.alert > .archive {
  float: right; }

.alert > .archive:hover {
  cursor: pointer;
  opacity: 0.5; }

.alert > .alert-content {
  padding: 0 2em; }

.alert > .alert-content > .alert-title {
  display: block;
  font-family: "Calibri Bold", "Calibri", sans-serif;
  font-size: 1.4em;
  line-height: 1em;
  word-wrap: break-word;
  overflow-wrap: break-word; }

.alert > .alert-content > .alert-message {
  font-family: "Calibri Bold", "Calibri", sans-serif;
  font-size: 1.2em;
  line-height: 1.2em;
  color: #5d6e7f; }

/* Primary colors */
/* Secondary colors */
.badge {
  position: relative;
  display: inline-block;
  font-family: "Calibri Regular", "Calibri", sans-serif;
  text-align: center;
  color: #fff;
  font-size: .7em;
  line-height: 1.5em;
  height: 1.5em;
  border-radius: 1em;
  min-width: 2em;
  padding: 0 0.125em;
  background-color: #5d6e7f;
  margin: 0 0.625em; }

.badge.big {
  line-height: 1.5625em;
  height: 1.5625em;
  border-radius: 0.785em;
  min-width: 2em;
  padding: 0 0.25em; }

/* Primary colors */
/* Secondary colors */
.button {
  font-size: 1em;
  height: 3.6em;
  padding: 0 1.5em;
  border: 0;
  border-radius: 0.4em;
  background-color: #e1e7eb;
  box-shadow: inset 0 -0.2em 0 0 #0000001a;
  color: #2a3b4c;
  outline: none; }

.button:hover {
  background-color: #cfd4d7;
  box-shadow: none; }

.button:active {
  background-color: #e1e7eb; }

.button:disabled {
  background-color: #e1e7eb;
  opacity: 0.5; }

.button:disabled:hover {
  box-shadow: inset 0 -0.2em 0 0 #0000001a; }

.button > .icon,
.button > .label {
  font-size: 1.6em;
  line-height: 1em; }

.button > .icon {
  min-width: 0.625em; }

.button > .label {
  font-family: "Calibri Regular", "Calibri", sans-serif;
  min-width: 2.5em; }

.button > .icon + .label,
.button > .label + .icon {
  padding-left: 0.625em; }

.button.big {
  height: 5em; }

.button.big > .icon,
.button.big > .label {
  font-size: 2em;
  line-height: 1em; }

.button.big > .icon {
  min-width: 1.5em; }

.button.big > .label {
  min-width: 3.5em; }

.button.small {
  height: 3em; }

.button.small > .icon,
.button.small > .label {
  font-size: 1.4em;
  line-height: 1em; }

.button.primary {
  background-color: #25bd59;
  color: #fff; }

.button.primary:hover {
  background-color: #21a94f; }

.button.primary:active {
  background-color: #25bd59; }

.button.primary:disabled {
  background-color: #25bd59; }

.button.cta {
  background-color: #f58025;
  color: #fff; }

.button.cta:hover {
  background-color: #dc7221; }

.button.cta:active {
  background-color: #f58025; }

.button.cta:disabled {
  background-color: #f58025; }

.button.ghost,
.button.ghost-inverted {
  background: transparent;
  box-shadow: none;
  border-width: 0.1em;
  border-style: solid; }

.button.ghost:disabled:hover,
.button.ghost-inverted:disabled:hover {
  box-shadow: none; }

.button.ghost {
  border-color: #25bd59;
  color: #25bd59; }

.button.ghost:hover {
  border-color: #21a94f;
  color: #21a94f; }

.button.ghost:active {
  border-color: #25bd59;
  color: #25bd59; }

.button.ghost:disabled {
  border-color: #25bd59;
  color: #25bd59; }

.button.ghost-inverted {
  border-color: #fff;
  color: #fff; }

.button.ghost-inverted:hover {
  border-color: #dfe6e9;
  color: #dfe6e9; }

.button.ghost-inverted:active {
  border-color: #fff;
  color: #fff; }

.button.ghost-inverted:disabled {
  border-color: #dfe6e9;
  color: #dfe6e9; }

.button.ghost-inverted-fill {
  background-color: #fff;
  color: #25bd59; }

.button.ghost-inverted-fill:hover {
  background-color: #dfe6e9;
  color: #21a94f; }

.button.ghost-inverted-fill:active {
  background-color: #fff; }

.button.ghost-inverted-fill:disabled {
  background-color: #fff; }

.button.ghost-inverted-fill:disabled:hover {
  color: #25bd59; }

.buttons-group {
  display: inline;
  border-radius: 0.4em;
}

.buttons-group > .button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttons-group > .button + .button {
  border-left: 0.1em solid #bfcdd4;
  border-radius: 0;
}

.buttons-group > .button:last-child {
  border-top-right-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
}

.checkbox {
  display: block;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.checkbox > input {
  position: absolute;
  opacity: 0;
}

.checkbox > .checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.4em;
  width: 1.4em;
  border: 0.1em solid #5d6e7f;
  border-radius: 0.3em;
}

.checkbox > input:checked ~ .checkbox-checkmark {
  background-color: #25bd59;
  border-color: #25bd59;
}

.checkbox > .checkbox-checkmark:after {
  position: absolute;
  display: none;
  content: "";
  left: 0.4em;
  top: 0;
  width: 0.4em;
  height: 0.9em;
  border: solid #fff;
  border-width: 0 0.2em 0.2em 0;
  transform: rotate(45deg);
}

.checkbox > input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Primary colors */
/* Secondary colors */
.grid {
  display: flex;
  flex-flow: column nowrap; }

.grid.loading > .activity-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.grid.loading .grid-row {
  opacity: 0.2; }

.grid .grid-row {
  width: 100%;
  height: 5.2em;
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 0.1em solid #e1e7eb;
  background-color: #ffffff; }
  .grid .grid-row--warning {
    background-color: rgba(235, 44, 81, 0.07); }

.grid .grid-header.grid-row {
  background-color: #f6f6f6; }

.grid .grid-row .grid-cell {
  color: #5d6e7f;
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1;
  word-break: break-word;
  margin-right: 5px;
  margin-left: 5px;
  overflow: hidden; }

.grid .grid-row .grid-cell.grow0 {
  flex-grow: 0;
  min-width: 1.6em;
  padding: 1.7em 2.2em 1.8em 0.5em; }

.grid .grid-row .grid-cell.grow0 > .grid-label {
  line-height: 0; }

.grid .grid-row .grid-cell.grow2 {
  flex-grow: 2; }

.grid .grid-row .grid-cell.grow3 {
  flex-grow: 3; }

.grid .grid-row .grid-cell > .grid-label {
  font-family: "Calibri Regular", "Calibri", sans-serif;
  font-size: 1.4em;
  line-height: 3.7em;
  overflow: hidden;
  min-width: 16px; }

.grid .grid-header.grid-row .grid-cell {
  text-transform: uppercase;
  color: #5d6e7f; }

.grid .grid-cell-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline; }

.grid .grid-cell-truncate-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden; }

.grid > .grid-row.selected {
  background-color: #e9f2ec;
  cursor: pointer;
}

.grid .grid-row .grid-cell.grid-checkbox {
  flex-grow: 0;
  min-width: 1.6em;
  padding: 1.7em 2.2em 1.8em 3.2em;
  cursor: default;
}

.grid.navigatable > .grid-row:hover {
  background-color: #e9f2ec;
  cursor: pointer;
}

.grid .grid-row .grid-cell.grid-navigation {
  flex-grow: 0;
  min-width: 1.6em;
}

.grid .grid-row .grid-cell.grid-navigation .grid-label {
  width: 100%;
  text-align: center;
}

.grid .grid-row.expandable {
  flex-wrap: wrap;
  overflow: hidden;
}

.grid .grid-row.expanded {
  background-color: #fff;
  height: auto;
}

.grid .grid-row .grid-columns {
  width: 100%;
  height: 5.2em;
  display: flex;
  flex-flow: row nowrap;
}

.grid .grid-row .grid-expanded {
  width: 100%;
  padding: 0 1.2em 1.8em 5.3em;
}

.grid .grid-row .grid-cell.grid-expand {
  flex-grow: 0;
  min-width: 1.6em;
  padding: 1.7em 1.4em 1.7em 2.1em;
}

.grid .grid-row .grid-cell.grid-expand > i {
  font-size: 1.8em;
  cursor: pointer;
}

.grid .grid-row .grid-cell.grid-expand > i:hover {
  color: #000;
}

/* Primary colors */
/* Secondary colors */
.header {
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  text-align: center;
  color: #ffffff;
  min-height: 10em;
  padding: 5em 0;
  flex-shrink: 0; }
  .header__title {
    font-size: 4em;
    line-height: 1.9em;
    color: #ffffff; }
  .header__subtitle {
    font-size: 2em;
    line-height: 1em; }

/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
.navigation {
  padding: 0 13.5em;
  background-color: #fff;
  border-bottom: 0.2em solid #d8d9db;
  width: 100%;
  padding: 0 30px; }
  .navigation__container {
    transition-property: margin, width, height, max-width;
    transition-duration: .3s;
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .navigation__container {
    display: flex; }
  .navigation a {
    color: #5d6E7F;
    font-family: "Calibri Regular", "Calibri", sans-serif;
    font-size: 1.4em;
    text-decoration: none;
    padding: 1em 1em;
    margin-bottom: -1px; }
    .navigation a:active, .navigation a.active, .navigation a:hover {
      border-bottom: 0.2em solid #25bd59; }

.navigation > a.active,
.navigation > a:active {
  color: #25bd59; }

/* Primary colors */
/* Secondary colors */
.no-data {
  padding-top: 10em;
  text-align: center; }

.no-data > i {
  color: #e1e7eb;
  font-size: 10em;
  line-height: 1em; }

.no-data > h2 {
  color: #414141;
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  font-size: 2em;
  line-height: 2.4em; }

.no-data > p {
  color: #5d6e7f;
  font-family: "Calibri Regular", "Calibri", sans-serif;
  font-size: 1.6em;
  line-height: 1em; }

.no-data > p > a,
.no-data > p > .link {
  color: #0096d6;
  cursor: pointer;
  text-decoration: none; }

/* Primary colors */
/* Secondary colors */
.popup {
  position: absolute;
  width: 40em;
  margin: 0 auto;
  padding-top: 2em;
  border-radius: 0.4em;
  background-color: #fff;
  border: 0.1em solid #e1e7eb;
  box-shadow: 0 0.2em 0.3em 0 rgba(0, 0, 0, 0.12); }

.popup > i.fa {
  font-size: 5.2em;
  line-height: 1em;
  color: #5d6e7f;
  padding: 0 0.3em;
  float: left; }

.popup > h1 {
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  font-weight: normal;
  font-size: 2em;
  line-height: 1.5em;
  padding-left: 4em;
  color: #414141;
  word-wrap: break-word;
  overflow-wrap: break-word; }

.popup > p {
  font-family: "Calibri Regular", "Calibri", sans-serif;
  font-size: 1.6em;
  line-height: 1.5em;
  padding-left: 5em;
  color: #5d6e7f; }

.popup > .buttons {
  padding: 1em;
  margin-top: 2.5em;
  background-color: #f6f6f6;
  border-top: 0.1em solid #e1e7eb;
  text-align: right; }

.popup > .buttons > .button {
  margin-left: 1em; }

.popup.danger .buttons > .button.primary {
  background-color: #eb2c51; }

.popup.danger .buttons > .button.primary:hover {
  background-color: #d32748; }

.upload-button > input {
  position: absolute;
  visibility: hidden;
  opacity: 0;
}


.notification-manager > .alert {
  position: relative;
  margin: 1em 1em 0 0;
}

.notification-manager > .alert.animated-notification-enter {
  top: 4em;
  opacity: 0;
}

.notification-manager > .alert.animated-notification-enter.animated-notification-enter-active {
  top: 0;
  opacity: 1;
  transition: 200ms ease-out;
}

.notification-manager > .alert.animated-notification-exit {
  left: 0;
  opacity: 1;
}

.notification-manager > .alert.animated-notification-exit.animated-notification-exit-active {
  left: 40em;
  height: 0;
  margin: 0;
  padding: 0 1.5em;
  border: 0;
  opacity: 0;
  transition: 200ms ease-in;
}

.notification-manager {
  position: fixed;
  top: 0;
  right: 0;
  padding: 1em;
  z-index: 9999;
}

/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
.manage-view > h2 {
  color: #414141;
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  font-size: 2em;
  line-height: 1.8em;
  -webkit-font-smoothing: antialiased; }

.manage-view > h2 > .badge {
  font-size: 0.8em;
  font-weight: normal; }

.manage-view > .upload-button {
  float: right; }

.manage-view > .no-data > p {
  color: #5d6e7f;
  font-family: "Calibri Regular", "Calibri", sans-serif;
  font-size: 1.6em;
  line-height: 1em; }

.manage-view > .grid-with-actions .action-bar {
  padding: 1.8em 0 1.2em; }

.manage-view > .grid-with-actions > .grid > .grid-row .grid-cell > .grid-label > .grid-addon-title {
  font-family: "Calibri Bold", "Calibri", sans-serif;
  font-size: 1.14em;
  color: #414141;
  display: flex;
  align-items: center; }

.manage-view > .grid-with-actions > .grid > .grid-row .grid-cell > .grid-label > .grid-addon-title > .grid-addon-name {
  flex: 0 1 auto; }

.manage-view > .grid-with-actions > .grid > .grid-row .grid-cell > .grid-label > .grid-addon-title > .badge {
  font-size: 1em; }

.manage-view {
  padding-top: 30px; }
  .manage-view__addon-icon {
    width: 1.14em;
    display: inline-block; }
  .manage-view .action-bar {
    background-color: #f6f6f6; }
  .manage-view .grid-addon-config-text {
    margin-left: 5px; }
  .manage-view .grid-addon-config--red {
    color: #eb2c51; }
  .manage-view .grid-addon-config--green {
    color: #25bd59; }
  .manage-view .grid-cell.grow0 {
    margin-left: 10px; }
  .manage-view .grid-cell.grid-checkbox + .grow0 {
    margin-left: 0; }

/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
.addon-view {
  height: 100%; }

.content-provider {
  display: flex;
  flex-flow: row wrap;
  height: 100%; }
  .content-provider__content {
    flex: 1 1 500px; }
  .content-provider__sidebar {
    width: 370px;
    flex: 0 0 auto;
    background-color: #ffffff;
    margin-left: 20px;
    display: flex;
    flex-flow: column nowrap;
    padding: 15px 0;
    margin-right: -30px; }
    @media screen and (max-width: 599px) {
      .content-provider__sidebar {
        margin: 0;
        width: 100%; } }
    .content-provider__sidebar-section {
      display: flex;
      flex-flow: column nowrap;
      margin: 20px 20px 0; }
      .content-provider__sidebar-section + .content-provider__sidebar-section {
        border-top: 1px solid #e1e7eb;
        padding-top: 20px; }
      .content-provider__sidebar-section-title {
        font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        line-height: 1.3;
        color: #2a3b4c;
        font-size: 20px; }
        .content-provider__sidebar-section-title .ask {
          color: #eb2c51; }
        .content-provider__sidebar-section-title-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between; }
      .content-provider__sidebar-section-message {
        font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        line-height: 1.3;
        color: #2a3b4c;
        font-size: 14px;
        margin-top: 15px; }
        .content-provider__sidebar-section-message--error {
          color: #eb2c51; }
        .content-provider__sidebar-section-message--success {
          color: #25bd59; }
      .content-provider__sidebar-section-button {
        margin: 5px;
        display: inline-block; }
        .content-provider__sidebar-section-buttons {
          margin: -5px;
          margin-top: 15px; }
      .content-provider__sidebar-section-status {
        font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
        line-height: 1.3;
        color: #2a3b4c;
        font-size: 14px; }
        .content-provider__sidebar-section-status--error {
          color: #eb2c51; }
        .content-provider__sidebar-section-status--success {
          color: #25bd59; }
  .content-provider__info {
    margin-top: 30px; }
  .content-provider__addon-name {
    font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
    line-height: 1.4;
    color: #2a3b4c;
    font-size: 24px; }
  .content-provider__addon-author {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 16px; }
    .content-provider__addon-author-text {
      color: #2a3b4c; }
  .content-provider__addon-image {
    width: 100%; }
    .content-provider__addon-image-wrap {
      display: flex;
      width: 128px;
      max-height: 128px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: 15px;
      flex-shrink: 0; }
  .content-provider__addon-description {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 14px;
    min-height: 64px;
    margin-top: 20px;
    display: flex; }
  .content-provider__actions {
    display: flex;
    flex-flow: row wrap;
    padding: 0 15px 15px;
    border-bottom: 1px solid #e1e7eb; }
    .content-provider__actions > * {
      margin: 5px; }
  .content-provider__extensions {
    margin-top: 30px;
    margin-bottom: 30px; }
    .content-provider__extensions-title {
      font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
      line-height: 1.4;
      color: #2a3b4c;
      font-size: 20px; }
    .content-provider__extensions .button {
      margin-top: 15px; }
  .content-provider__config {
    margin-top: 15px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start; }
    .content-provider__config-link {
      font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      line-height: 1.3;
      color: #2a3b4c;
      font-size: 14px;
      color: #298fc2;
      border: none;
      background-color: transparent;
      cursor: pointer;
      outline: 0; }
    .content-provider__config-age {
      font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
      line-height: 1.3;
      color: #2a3b4c;
      font-size: 14px;
      color: #c0cdd5;
      margin-top: 10px; }
  .content-provider__table {
    margin-top: 20px; }
  .content-provider__btn-refresh {
    margin-top: 15px; }

.table__expand-row-icon {
  color: #5d6e7f;
  background-color: transparent;
  border: none;
  outline: 0; }

.table .ant-table {
  color: #5d6e7f;
  border: none;
  font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif; }
  .table .ant-table table {
    border-collapse: collapse; }
  .table .ant-table-thead {
    text-transform: uppercase; }
    .table .ant-table-thead tr th {
      color: #5d6e7f; }
  .table .ant-table-tbody {
    background-color: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: hidden; }
  .table .ant-table-body {
    margin: 0 !important; }
  .table .ant-table-content {
    background-color: transparent; }

.status-tag__icon {
  margin-right: 5px; }

.status-tag--error {
  color: #eb2c51; }

.status-tag--deploy {
  color: #5d6e7f; }

.status-tag--success {
  color: #25bd59; }

.status-description {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 5px 10px; }
  .status-description--error {
    background-color: rgba(235, 44, 81, 0.07); }
  .status-description__text {
    display: inline-block;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    flex-grow: 1;
    width: 100%;
    flex-basis: 100%; }
  .status-description__icon {
    margin-top: 2px;
    margin-right: 7px; }
    .status-description__icon--error {
      color: #eb2c51; }
    .status-description__icon--success {
      color: #25bd59; }
    .status-description__icon--pending {
      color: #5d6e7f; }
    .status-description__icon--disabled {
      color: #5d6e7f; }
    .status-description__icon--waiting {
      color: #f7ea48; }

.transition-item {
  -webkit-animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
          animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  left: 0;
  width: 100%; }
  .transition-item-enter {
    position: absolute;
    z-index: 2; }
  .transition-item-exit {
    position: absolute;
    z-index: 1; }
    .transition-item-exit-done {
      opacity: 0; }

.manage-view.transition-item-enter {
  left: -100vw; }
  .manage-view.transition-item-enter-active {
    -webkit-animation-name: slideFromLeft;
            animation-name: slideFromLeft;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms; }

.manage-view.transition-item-exit {
  left: 0; }
  .manage-view.transition-item-exit-active {
    -webkit-animation-name: slideToLeft;
            animation-name: slideToLeft;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms; }

.addon-view.transition-item-enter {
  left: 100vw; }
  .addon-view.transition-item-enter-active {
    -webkit-animation-name: slideFromRight;
            animation-name: slideFromRight;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms; }

.addon-view.transition-item-exit {
  left: 0; }
  .addon-view.transition-item-exit-active {
    -webkit-animation-name: slideToRight;
            animation-name: slideToRight;
    -webkit-animation-duration: 400ms;
            animation-duration: 400ms; }

@-webkit-keyframes slideFromRight {
  from {
    left: 100vw;
    opacity: 0; }
  to {
    left: 0;
    opacity: 1; } }

@keyframes slideFromRight {
  from {
    left: 100vw;
    opacity: 0; }
  to {
    left: 0;
    opacity: 1; } }

@-webkit-keyframes slideFromLeft {
  from {
    left: -100vw;
    opacity: 0; }
  to {
    left: 0;
    opacity: 1; } }

@keyframes slideFromLeft {
  from {
    left: -100vw;
    opacity: 0; }
  to {
    left: 0;
    opacity: 1; } }

@-webkit-keyframes slideToRight {
  from {
    left: 0;
    opacity: 1; }
  to {
    left: 100vw;
    opacity: 0; } }

@keyframes slideToRight {
  from {
    left: 0;
    opacity: 1; }
  to {
    left: 100vw;
    opacity: 0; } }

@-webkit-keyframes slideToLeft {
  from {
    left: 0;
    opacity: 1; }
  to {
    left: -100vw;
    opacity: 0; } }

@keyframes slideToLeft {
  from {
    left: 0;
    opacity: 1; }
  to {
    left: -100vw;
    opacity: 0; } }

.popup-manager .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(93, 110, 127, 0.7);
  z-index: 9999;
}

.popup-manager .popup {
  position: relative;
  margin: 0 auto;
  top: 35%;
}

.popup-manager .popup.animated-popup-enter {
  top: 45%;
  opacity: 0;
}

.popup-manager .popup.animated-popup-enter.animated-popup-enter-active {
  top: 35%;
  opacity: 1;
  transition: 200ms ease-out;
}

.popup-manager .popup.animated-popup-exit {
  left: 0;
  opacity: 1;
}

.popup-manager .popup.animated-popup-exit.animated-popup-exit-active {
  left: 40em;
  opacity: 0;
  transition: 200ms ease-in;
}

.popup-manager {
  z-index: 9999;
}

/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
html, body {
  font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  font-size: 10px;
  line-height: 1em;
  background-color: #f6f6f6;
  color: #5d6e7f;
  height: 100%; }

body,
body * {
  margin: 0;
  padding: 0; }

#root {
  height: 100%; }

.app {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .app__wrapper {
    height: 100%; }
  .app__alert {
    color: #cc9803;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #cc9803;
    font-weight: bold; }
    .app__alert .ant-alert-message {
      color: #cc9803; }
    .app__alert .ant-alert-icon {
      color: #cc9803; }

a {
  outline: 0; }

.content {
  width: 100%;
  padding: 0 30px;
  flex-grow: 1; }
  .content__container {
    transition-property: margin, width, height, max-width;
    transition-duration: .3s;
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .content__container {
    height: 100%;
    position: relative; }

.btn {
  background-color: transparent;
  color: #2a3b4c;
  border-color: #2a3b4c;
  text-shadow: none;
  box-shadow: none;
  background-color: #e1e7eb;
  color: #2a3b4c;
  border-color: #e1e7eb; }
  .btn:hover, .btn:active, .btn:focus {
    color: #2a3b4c;
    border-color: #2a3b4c;
    background-color: transparent; }
  .btn:hover, .btn:active, .btn:focus {
    background-color: #c2cfd7;
    color: #2a3b4c;
    border-color: #c2cfd7; }
  .btn--upload {
    background-color: transparent;
    color: #2a3b4c;
    border-color: #2a3b4c;
    text-shadow: none;
    box-shadow: none;
    background-color: #25bd59;
    color: #ffffff;
    border-color: #25bd59; }
    .btn--upload:hover, .btn--upload:active, .btn--upload:focus {
      color: #2a3b4c;
      border-color: #2a3b4c;
      background-color: transparent; }
    .btn--upload:hover, .btn--upload:active, .btn--upload:focus {
      background-color: #1d9245;
      color: #ffffff;
      border-color: #1d9245; }
  .btn--back {
    margin-top: 30px;
    background-color: transparent;
    color: #2a3b4c;
    border-color: #2a3b4c;
    text-shadow: none;
    box-shadow: none;
    background-color: #e1e7eb;
    color: #2a3b4c;
    border-color: #e1e7eb; }
    .btn--back:hover, .btn--back:active, .btn--back:focus {
      color: #2a3b4c;
      border-color: #2a3b4c;
      background-color: transparent; }
    .btn--back:hover, .btn--back:active, .btn--back:focus {
      background-color: #c2cfd7;
      color: #2a3b4c;
      border-color: #c2cfd7; }
  .btn--add {
    background-color: transparent;
    color: #2a3b4c;
    border-color: #2a3b4c;
    text-shadow: none;
    box-shadow: none;
    background-color: #25bd59;
    color: #ffffff;
    border-color: #25bd59; }
    .btn--add:hover, .btn--add:active, .btn--add:focus {
      color: #2a3b4c;
      border-color: #2a3b4c;
      background-color: transparent; }
    .btn--add:hover, .btn--add:active, .btn--add:focus {
      background-color: #1d9245;
      color: #ffffff;
      border-color: #1d9245; }

@font-face {
  font-family: "Sommet Rounded Regular";
  src: url(/addon/ui/static/media/SommetRoundedRegular.6b2af0a8.eot);
  src: local("O"),
        url(/addon/ui/static/media/SommetRoundedRegular.bcbb30b9.woff) format("woff"),
        url(/addon/ui/static/media/SommetRoundedRegular.2ff97c5d.ttf) format("truetype"),
        url(/addon/ui/static/media/SommetRoundedRegular.eade2960.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Calibri Regular";
  src: url(/addon/ui/static/media/CalibriRegular.79b0cd60.eot);
  src: local("O"),
        url(/addon/ui/static/media/CalibriRegular.7ac33402.woff) format("woff"),
        url(/addon/ui/static/media/CalibriRegular.f3afc07f.ttf) format("truetype"),
        url(/addon/ui/static/media/CalibriRegular.36145799.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}



/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
/* Primary colors */
/* Secondary colors */
.develop {
  font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  line-height: 1.3;
  color: #2a3b4c;
  font-size: 14px;
  display: flex;
  flex-flow: row wrap;
  color: #2a3b4c; }
  .develop__content {
    flex: 1 1 500px;
    padding-top: 30px; }
  .develop__sidebar {
    flex: 0 0 auto;
    display: flex;
    flex-flow: column nowrap;
    width: 370px;
    padding: 30px;
    margin-left: 20px;
    margin-right: -30px;
    background-color: #ffffff; }
  .develop__title {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 24px; }
  .develop__about {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 14px;
    margin-top: 15px; }
  .develop__column {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
    margin: -10px; }
    .develop__columns {
      display: flex;
      flex-flow: column nowrap;
      padding-top: 20px;
      margin-top: -15px; }
    .develop__column-item {
      margin: 10px;
      flex-grow: 1;
      width: 50%; }
    .develop__column:after {
      content: '';
      display: block;
      margin: 10px;
      flex-grow: 1;
      width: 50%;
      height: 0;
      padding: 0 15px;
      flex-basis: 350px; }
      @media screen and (min-width: 1400px) {
        .develop__column:after {
          flex-basis: 500px; } }
  .develop__group-title {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 20px;
    width: 100%;
    margin: 15px 0; }
  .develop__appstore-title {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 24px; }
  .develop__appstore-text {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px; }
  .develop__appstore-button {
    background-color: transparent;
    color: #2a3b4c;
    border-color: #2a3b4c;
    text-shadow: none;
    box-shadow: none;
    background-color: #25bd59;
    color: #ffffff;
    border-color: #25bd59; }
    .develop__appstore-button:hover, .develop__appstore-button:active, .develop__appstore-button:focus {
      color: #2a3b4c;
      border-color: #2a3b4c;
      background-color: transparent; }
    .develop__appstore-button:hover, .develop__appstore-button:active, .develop__appstore-button:focus {
      background-color: #1d9245;
      color: #ffffff;
      border-color: #1d9245; }

.link-card {
  display: block;
  color: #2a3b4c;
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #e1e7eb;
  border-radius: 4px;
  transition: all .3s ease;
  flex-basis: 350px; }
  @media screen and (min-width: 1400px) {
    .link-card {
      flex-basis: 500px; } }
  .link-card:hover {
    color: #2a3b4c;
    border-color: #298fc2;
    box-shadow: 0 1px 8px 1px #e1e7eb; }
  .link-card__title {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 14px;
    color: #298fc2; }
  .link-card__text {
    font-family: "Calibri Regular", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    line-height: 1.3;
    color: #2a3b4c;
    font-size: 14px;
    margin-top: 15px; }

.login-callback {
  width: 100%;
  height: 100%;
  max-width: 1070px;
  padding: 30px;
  margin: 0 auto;
}

