.checkbox {
  display: block;
  position: relative;
  width: 1.6em;
  height: 1.6em;
  user-select: none;
}

.checkbox > input {
  position: absolute;
  opacity: 0;
}

.checkbox > .checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.4em;
  width: 1.4em;
  border: 0.1em solid #5d6e7f;
  border-radius: 0.3em;
}

.checkbox > input:checked ~ .checkbox-checkmark {
  background-color: #25bd59;
  border-color: #25bd59;
}

.checkbox > .checkbox-checkmark:after {
  position: absolute;
  display: none;
  content: "";
  left: 0.4em;
  top: 0;
  width: 0.4em;
  height: 0.9em;
  border: solid #fff;
  border-width: 0 0.2em 0.2em 0;
  transform: rotate(45deg);
}

.checkbox > input:checked ~ .checkbox-checkmark:after {
  display: block;
}
