@import "../variables/index";

$default-bg-color: $color-grey;
$default-text-color: $color-text;

@mixin button-base {
  background-color: transparent;
  color: $default-text-color;
  border-color: $default-text-color;
  text-shadow: none;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    color: $default-text-color;
    border-color: $default-text-color;
    background-color: transparent;
  }
}

@mixin button-primary($textColor: $default-text-color, $bgColor: $default-bg-color) {
  @include button-base;
  background-color: $bgColor;
  color: $textColor;
  border-color: $bgColor;

  &:hover,
  &:active,
  &:focus {
    background-color: adjust-color($bgColor, $lightness: -10%);
    color: $textColor;
    border-color: adjust-color($bgColor, $lightness: -10%);
  }
}

@mixin button-secondary($textColor: $default-text-color) {
  @include button-base;
  color: $textColor;
  border-color: $textColor;

  &:hover,
  &:active,
  &:focus {
    color: adjust-color($textColor, $lightness: -10%);
    border-color: adjust-color($textColor, $lightness: -10%);
  }
}
