@import "../../scss/variables/index";

.no-data {
  padding-top: 10em;
  text-align: center;
}

.no-data > i {
  color: #e1e7eb;
  font-size: 10em;
  line-height: 1em;
}

.no-data > h2 {
  color: #414141;
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  font-size: 2em;
  line-height: 2.4em;
}

.no-data > p {
  color: #5d6e7f;
  font-family: "Calibri Regular", "Calibri", sans-serif;
  font-size: 1.6em;
  line-height: 1em;
}

.no-data > p > a,
.no-data > p > .link {
  color: #0096d6;
  cursor: pointer;
  text-decoration: none;
}
