@import "../../scss/mixins/index";

.activity-overlay {
  display: flex;
  padding: 3.5em;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__content {
    @include text-h4;
  }
}
