.grid .grid-row.expandable {
  flex-wrap: wrap;
  overflow: hidden;
}

.grid .grid-row.expanded {
  background-color: #fff;
  height: auto;
}

.grid .grid-row .grid-columns {
  width: 100%;
  height: 5.2em;
  display: flex;
  flex-flow: row nowrap;
}

.grid .grid-row .grid-expanded {
  width: 100%;
  padding: 0 1.2em 1.8em 5.3em;
}

.grid .grid-row .grid-cell.grid-expand {
  flex-grow: 0;
  min-width: 1.6em;
  padding: 1.7em 1.4em 1.7em 2.1em;
}

.grid .grid-row .grid-cell.grid-expand > i {
  font-size: 1.8em;
  cursor: pointer;
}

.grid .grid-row .grid-cell.grid-expand > i:hover {
  color: #000;
}
