$width-desktop: 1200px;
$width-tablet-landscape: 900px;
$width-tablet-portrait: 600px;

// Media queries:

@mixin media-from-tablet-portrait {
  @media screen and (min-width: $width-tablet-portrait) { @content; }
}

@mixin media-from-tablet-landscape {
  @media screen and (min-width: $width-tablet-landscape) { @content; }
}

@mixin media-from-desktop {
  @media screen and (min-width: $width-desktop) { @content; }
}

@mixin media-till-tablet-portrait {
  @media screen and (max-width: $width-tablet-portrait - 1) { @content; }
}

@mixin media-till-tablet-landscape {
  @media screen and (max-width: $width-tablet-landscape - 1) { @content; }
}

@mixin media-till-desktop {
  @media screen and (max-width: $width-desktop - 1) { @content; }
}

// Content layout helpers:

@mixin content {
  width: 100%;
  padding: 0 $space-large;

  &__container {
    transition-property: margin, width, height, max-width;
    transition-duration: .3s;
    transition-timing-function: ease-out;
    margin: 0 auto;

    // @include media-from-tablet-portrait {
    //   max-width: 720px;
    // }

    // @include media-from-tablet-landscape {
    //   max-width: 960px;
    // }

    // @include media-from-desktop {
    //   max-width: 1500px;
    // }
  }
}
