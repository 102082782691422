/* Primary colors */
$color-green:				    #25bd59;
$color-slate-blue:			#5d6e7f;
$color-light-slate-blue:#c0cdd5;
$color-white:				    #ffffff;
$color-black:				    #000000;
$color-brown:				    #414141;

/* Secondary colors */
$color-cloud-grey:			#dbe2e9;
$color-lemon-yellow:		#f7ea48;
$color-lime-green:			#dbe442;
$color-pear-green:			#a4d65e;
$color-pine-green:			#008264;
$color-aqua-blue:			  #008c95;
$color-teal-blue:			  #59bec9;
$color-cerulean-blue:		#298fc2;
$color-aegean-blue:			#0076a8;
$color-denim-blue:			#002855;

$color-light-grey:			#fbfbfb;
$color-red:				    	#eb2c51;




$color-primary: $color-green;
$color-secondary: $color-cerulean-blue;
$color-grey-dark: $color-slate-blue;
$color-grey: #e1e7eb;
$color-grey-light: #f6f6f6;
$color-error: $color-red;
$color-border: $color-cloud-grey;
$color-text: #2a3b4c; 
$color-text-dimmed: $color-light-slate-blue;
$color-error-transparent: transparentize($color-red, .93);
