@import "../../scss/variables/index";

.alert {
  overflow: hidden;
  padding: 2.2em 1.5em;
  border-width: 0.1em;
  border-style: solid;
  border-radius: 0.4em;
  width: 36em;
}

.alert.success {
  background-color: #e7f9f1;
  border-color: #0f955e;
  color: #0f955e;
}

.alert.error {
  background-color: #faeded;
  border-color: #d04d52;
  color: #d04d52;
}

.alert.warning {
  background-color: #fff8e3;
  border-color: #cb9700;
  color: #cb9700;
}

.alert.info {
  background-color: #ebf1f8;
  border-color: #457cbf;
  color: #457cbf;
}

.alert > .fa {
  float: left;
  font-size: 1.4em;
  line-height: 1em;
}

.alert > .archive {
  float: right;
}

.alert > .archive:hover {
  cursor: pointer;
  opacity: 0.5;
}

.alert > .alert-content {
  padding: 0 2em;
}

.alert > .alert-content > .alert-title {
  display: block;
  font-family: "Calibri Bold", "Calibri", sans-serif;
  font-size: 1.4em;
  line-height: 1em;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.alert > .alert-content > .alert-message {
  font-family: "Calibri Bold", "Calibri", sans-serif;
  font-size: 1.2em;
  line-height: 1.2em;
  color: #5d6e7f;
}
