.grid > .grid-row.selected {
  background-color: #e9f2ec;
  cursor: pointer;
}

.grid .grid-row .grid-cell.grid-checkbox {
  flex-grow: 0;
  min-width: 1.6em;
  padding: 1.7em 2.2em 1.8em 3.2em;
  cursor: default;
}
