@import "../variables/index";

@mixin header-base {
  font-family: $font-primary;
  line-height: 1.4;
  color: $color-text;
}

@mixin text-base {
  font-family: $font-secondary;
  line-height: 1.3;
  color: $color-text;
}

@mixin header-h1 {
  @include header-base;
  font-size: 40px;
}

@mixin header-h2 {
  @include header-base;
  font-size: 32px;
}

@mixin header-h3 {
  @include header-base;
  font-size: 24px;
}

@mixin header-h4 {
  @include header-base;
  font-size: 20px;
}

@mixin text-h1 {
  @include text-base;
  font-size: 32px;
}

@mixin text-h2 {
  @include text-base;
  font-size: 24px;
}

@mixin text-h3 {
  @include text-base;
  font-size: 20px;
}

@mixin text-h4 {
  @include text-base;
  font-size: 16px;
}

@mixin text-p {
  @include text-base;
  font-size: 14px;
}

@mixin text-s {
  @include text-base;
  font-size: 12px;
}
