.buttons-group {
  display: inline;
  border-radius: 0.4em;
}

.buttons-group > .button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.buttons-group > .button + .button {
  border-left: 0.1em solid #bfcdd4;
  border-radius: 0;
}

.buttons-group > .button:last-child {
  border-top-right-radius: 0.4em;
  border-bottom-right-radius: 0.4em;
}
