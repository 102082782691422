@import "../../scss/variables/index";
@import "../../scss/mixins/index";

.navigation {
  padding: 0 13.5em;
  background-color: #fff;
  border-bottom: 0.2em solid #d8d9db;

  @include content;

  &__container {
    display: flex;
  }

  a {
    color: #5d6E7F;
    font-family: "Calibri Regular", "Calibri", sans-serif;
    font-size: 1.4em;
    text-decoration: none;
    padding: 1em 1em;
    margin-bottom: -1px;

    &:active,
    &.active,
    &:hover {
      border-bottom: 0.2em solid #25bd59;
    }
  }
}

.navigation > a.active,
.navigation > a:active {
  color: #25bd59;
}
