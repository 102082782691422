@import "../../../../scss/variables/index";
@import "../../../../scss/mixins/index";

.manage-view > h2 {
  color: #414141;
  font-family: "Sommet Rounded Regular", "Sommet Rounded", sans-serif;
  font-size: 2em;
  line-height: 1.8em;
  -webkit-font-smoothing: antialiased;
}

.manage-view > h2 > .badge {
  font-size: 0.8em;
  font-weight: normal;
}

.manage-view > .upload-button {
  float: right;
}

.manage-view > .no-data > p {
	color: #5d6e7f;
	font-family: "Calibri Regular", "Calibri", sans-serif;
	font-size: 1.6em;
  line-height: 1em;
}

.manage-view > .grid-with-actions .action-bar {
  padding: 1.8em 0 1.2em;
}

.manage-view > .grid-with-actions > .grid > .grid-row .grid-cell > .grid-label > .grid-addon-title {
  font-family: "Calibri Bold", "Calibri", sans-serif;
  font-size: 1.14em;
  color: #414141;
  display: flex;
  align-items: center;
}

.manage-view > .grid-with-actions > .grid > .grid-row .grid-cell > .grid-label > .grid-addon-title > .grid-addon-name {
  flex: 0 1 auto;
}


.manage-view > .grid-with-actions > .grid > .grid-row .grid-cell > .grid-label > .grid-addon-title > .badge {
  font-size: 1em;
}

.manage-view {
  padding-top: $space-large;

  &__addon-icon {
    width: 1.14em;
    display: inline-block;
  }

  .action-bar {
    background-color: $color-grey-light;
  }

  .grid-addon-config {
    &-text {
      margin-left: $space-tiny-half;
    }

    &--red {
      color: $color-error;
    }

    &--green {
      color: $color-green;
    }
  }

  .grid-cell {
    &.grow0 {
      margin-left: $space-tiny;
    }

    &.grid-checkbox {
      & + .grow0 {
        margin-left: 0;
      }
    }
  }
}
