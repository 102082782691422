@import "../../scss/variables/index";
@import "../../scss/mixins/index";

.develop {
    @include text-p;
    display: flex;
    flex-flow: row wrap;
    color: $color-text;

    &__content {
        flex: 1 1 500px;
        padding-top: 30px;
    }

    &__sidebar {
        flex: 0 0 auto;
        display: flex;
        flex-flow: column nowrap;
        width: 370px;
        padding: $space-large;
        margin-left: $space-medium;
        margin-right: -$space-large;
        background-color: $color-white;
    }

    &__title {
        @include text-h2;
    }

    &__about {
        @include text-p;
        margin-top: $space-small;
    }

    &__column {
        flex: 1 1 auto;
        display: flex;
        flex-wrap: wrap;
        margin: -$space-tiny;

        &s {
            display: flex;
            flex-flow: column nowrap;
            padding-top: $space-medium;
            margin-top: -$space-small;
        }

        &-item {
            margin: $space-tiny;
            flex-grow: 1;
            width: 50%;
        }

        &:after {
            content: '';
            display: block;
            margin: $space-tiny;
            flex-grow: 1;
            width: 50%;
            height: 0;
            padding: 0 15px;
            flex-basis: 350px;

            @media screen and (min-width: 1400px) {
                flex-basis: 500px;
            }
        }
    }

    &__group-title {
        @include text-h3;
        width: 100%;
        margin: $space-small 0;
    }

    &__appstore {
        &-title {
            @include text-h2;
        }

        &-text {
            @include text-p;
            margin-top: $space-medium;
            margin-bottom: $space-medium;
        }

        &-button {
            @include button-primary($color-white, $color-primary);
        }
    }
}

.link-card {
    display: block;
    color: $color-text;
    background-color: $color-white;
    padding: $space-small;
    border: 1px solid $color-grey;
    border-radius: 4px;
    transition: all .3s ease;
    flex-basis: 350px;

    @media screen and (min-width: 1400px) {
        flex-basis: 500px;
    }

    &:hover {
        color: $color-text;
        border-color: $color-secondary;
        box-shadow: 0 1px 8px 1px $color-grey;
    }

    &__title {
        @include text-p;
        color: $color-secondary;
    }

    &__text {
        @include text-p;
        margin-top: $space-small;
    }
}
