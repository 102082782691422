.popup-manager .popup {
  position: relative;
  margin: 0 auto;
  top: 35%;
}

.popup-manager .popup.animated-popup-enter {
  top: 45%;
  opacity: 0;
}

.popup-manager .popup.animated-popup-enter.animated-popup-enter-active {
  top: 35%;
  opacity: 1;
  transition: 200ms ease-out;
}

.popup-manager .popup.animated-popup-exit {
  left: 0;
  opacity: 1;
}

.popup-manager .popup.animated-popup-exit.animated-popup-exit-active {
  left: 40em;
  opacity: 0;
  transition: 200ms ease-in;
}
